import AppStore from "./appStore";
import {makeAutoObservable, runInAction} from "mobx";

export class IdentData {
	public userId = ""
	public name = "Anonymous"
	public picture = "https://"
	public email: string = ""
	public isAuthenticated: boolean = false
	constructor() {
		makeAutoObservable(this);
	}
}

export class IdentStore {
	private appStore: AppStore
	private inAuth = false
	public IdentData = new IdentData()
	
	constructor(appStore: AppStore) {
		this.appStore = appStore
		this.getFromLocalStorage()
		this.tryAuth()
		makeAutoObservable(this);
	}
	
	public tryAuth() {
		return;
		if (this.inAuth || this.IdentData.isAuthenticated) return
		this.inAuth = true;
		fetch('/.auth/me').then((response) => {
			return response.json();
		}).then((data) => {
			this.setCredentialResponse(data)
		}).then(() => {
			runInAction(() => {this.inAuth = false})
		})
	}

	public setCredentialResponse(credential) {
		if (credential.clientPrincipal == null) {
			this.IdentData.userId = ""
			this.IdentData.name = "Anonymous"
			this.IdentData.picture = "https://"
			this.IdentData.email = ""
			this.IdentData.isAuthenticated = false
			return
		}

		if (credential.clientPrincipal.identityProvider === "google") {
			this.IdentData.isAuthenticated = true
			this.IdentData.userId = this.getGoogleClaim("userId", credential.clientPrincipal.claims)
			this.IdentData.name = this.getGoogleClaim("name", credential.clientPrincipal.claims)
			this.IdentData.picture = this.getGoogleClaim("picture", credential.clientPrincipal.claims)
			this.IdentData.email = this.getGoogleClaim("email", credential.clientPrincipal.claims)
		}
		
		this.updateStorage()
	}

	public logOut() {
		runInAction(() => {
			this.IdentData.userId = ""
			this.IdentData.name = "Anonymous"
			this.IdentData.picture = "https://"
			this.IdentData.email = ""
			this.IdentData.isAuthenticated = false
		})
		fetch('/.auth/logout').then((response) => {
			return response.json();
		}).then((data) => {
		})
	}
	
	public purge() {
		fetch('/.auth/purge').then((response) => {
			return response.json();
		}).then((data) => {
		})
		//this.logOut()
	}
	private getGoogleClaim(name, claims) {
		for (var i = 0; i < claims.length; i++) {
			if (claims[i].typ === name) return claims[i].val
		}
		return ""
	}
	
	private updateStorage() {
		localStorage.setItem("cavok.ident", JSON.stringify({
			userId: this.IdentData.userId,
			name: this.IdentData.name,
			picture: this.IdentData.picture,
			email: this.IdentData.email,
			isAuthenticated: this.IdentData.isAuthenticated
		}))
	}
	
	private getFromLocalStorage() {
		try {
			const json = localStorage.getItem("cavok.ident."+this.IdentData.userId)
			if (json) {
				const i = JSON.parse(json)
				this.IdentData.userId = i.userId
				this.IdentData.name = i.name
				this.IdentData.picture = i.picture
				this.IdentData.email = i.email
				this.IdentData.isAuthenticated = i.isAuthenticated
			}
			console.log("IdentStore: Loaded from local storage", this.IdentData)
		}
		catch (e) {
			localStorage.removeItem("cavok.ident")
		}
	}
	
	
}