import { observer } from "mobx-react";
import styles from "./homePage.module.css"
import React from "react";
import AppStore from "../../../stores/appStore";
//import Turnstile, { useTurnstile } from "react-turnstile";

export const HomePage = observer(() => {

	const identStore = AppStore.getInstance().identStore
	//	const turnstile = useTurnstile();
	//identStore.logOut()

	return (
		<div>
			<div className={'appContent'}>
				<div className={styles.page}>
					<div className={styles.fader}>
						<div className={styles.left}>
							<h1><span><a href={'/'}><span className={'logo1large'}>CAV</span><span
								className={'logo2large'}>OK</span></a></span> is AI-powered PPL radio voice
								communication learning.</h1>
							<h2>Practice two-way radio communication at your own pace, on your journey to become a
								skilled aviator.</h2>

							<a href={'/menu'}>Continue</a>

						</div>
					</div>
				</div>
			</div>
			<div className={'footer noPrint'}>
				<p>This site uses cookies. By using this site, you consent to your voice being transmitted to
					the cloud and processed by AI. We collect and store the information your provide when
					authenticating. Visit the account page for more information.</p>
				<p>All Intellectual Property Rights belong to cavok.dk 2024</p>
			</div>
		</div>
	)
});
